import React from "react";

function App() {
  return (
    <div className="container">
      {/* Nav Links */}
      <div className="navbar">
        <a href="https://raydium.io/swap/?outputMint=8CuWgCs7dCphqT6XV22wupACedU1kXQMruCxxZLNy6W6" target="_blank" rel="noreferrer">
          BUY
        </a>
        <a href="https://linktr.ee/" target="_blank" rel="noreferrer">
          LINKS
        </a>
        <a
          href="https://dexscreener.com/solana/8CuWgCs7dCphqT6XV22wupACedU1kXQMruCxxZLNy6W6"
          target="_blank"
          rel="noreferrer"
        >
          CHART
        </a>
      </div>

      {/* Main Image and Text */}
      <img src="/images/main-egg.png" alt="egg" className="main-image" />

      <h2>
        A TRUE FRIEND IS SOMEONE WHO THINKS THAT YOU ARE A GOOD EGG EVEN THOUGH
        HE KNOWS THAT YOU ARE SLIGHTLY CRACKED
      </h2>

      {/* Buy and Token Address */}
      <a href="https://raydium.io/swap/?outputMint=8CuWgCs7dCphqT6XV22wupACedU1kXQMruCxxZLNy6W6" target="_blank" rel="noreferrer">
        <button className="button">BUY SOME EGGS!</button>
      </a>

      <h2>8CuWgCs7dCphqT6XV22wupACedU1kXQMruCxxZLNy6W6</h2>

      {/* <iframe
      width={1000}
      height={500}
        src="https://raydium.io/swap/?outputMint=8CuWgCs7dCphqT6XV22wupACedU1kXQMruCxxZLNy6W6"
        title="Raydium Embed"
      ></iframe> */}

      {/* Socials */}
      <div className="social-icons">
        <a
          href="https://t.me/+pzvEp6CS4kc4N2Rk"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/telegram.svg" alt="Icon 1" />
        </a>
        <a href="https://x.com/coin_egg81998" target="_blank" rel="noreferrer">
          <img src="/images/twitter.svg" alt="Icon 2" />
        </a>
        <a
          href="https://dexscreener.com/solana/3B5wuUrMEi5yATD7on46hKfej3pfmd7t1RKgrsN3pump"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/dexscreener.svg" alt="Icon 3" />
        </a>
        <a
          href="https://www.dextools.io/app/en/solana/pair-explorer/9uWW4C36HiCTGr6pZW9VFhr9vdXktZ8NA8jVnzQU35pJ"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/dextool.svg" alt="Icon 4" />
        </a>
      </div>

      {/* Meme creation */}
      <h2>MAKE YOUR OWN EGG MEME!</h2>

      <img src="/images/eggs.gif" alt="egg gif" className="main-image" />

      <button className="button">COMING SOON</button>
    </div>
  );
}

export default App;
